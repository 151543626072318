<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-data-table
      :headers="headers"
      :items="stockAdjustments"
      :loading="isLoadingStockAdjustments"
      :server-items-length="stockAdjustmentsMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
    >

    <template v-slot:item.validate="{ item }">
        <v-btn
          v-if="item.stock_adjustment_status != 'canceled'"
          class="primary"
          @click.stop="validate(item)"
          small
          >Validate</v-btn
        >
      </template>

      <template v-slot:item.update_by="{ item }">
        <span v-if="item.stock_adjustment_status == 'canceled'">
        {{ item.canceled_by.name }}
        </span>
        <span v-if="item.stock_adjustment_status == 'closed'">
        {{ item.checked_by.name }}
        </span>
      </template>

      <template v-slot:item.update_date="{ item }">
        <span v-if="item.stock_adjustment_status == 'canceled'">
        {{ item.canceled_at }}
        </span>
        <span v-if="item.stock_adjustment_status == 'closed'">
        {{ item.validated_at }}
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('supplyOrder-view')"
          class="mr-2"
          color="#C6C6C6"
          @click.stop="showItem(item)"
        >
          $vuetify.icons.iconViewItem
        </v-icon>
        <v-icon
          class="mr-2"
          color="#C6C6C6"
          @click.stop="downloadItem(item)"
        >
          $vuetify.icons.iconDownload
        </v-icon>
      </template>
      <template v-slot:top>
        <v-row class="my-2">
          <v-col
            cols="12"
            sm="2"
            md="2"
          >
            <v-text-field
              outlined
              dense
              hide-details
              v-model="filters.search"
              :label="$t('search')"
              class=""
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="2"
            md="2"
            class="pl-0"
          >
            <v-autocomplete
              outlined
              clearable
              dense
              hide-details
              v-model="filters.hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_hub"
              :loading="is_loading_hubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            md="2"
            class="pl-0"
          >
            <v-autocomplete
              outlined
              dense
              hide-details
              clearable
              v-model="filters.stock_adjustment_status"
              :items="[
                { id: 'closed', name: 'Closed' },
                { id: 'canceled', name: 'Canceled' },
              ]"
              item-text="name"
              item-value="id"
              :label="$t('status')"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="px-0"
          >
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  v-model="filters.created_date_range"
                  :label="$t('created_date_range')"
                  append-icon="mdi-calendar"
                  readonly
                  chips
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.created_date_range"
                range
                no-title
                color="primary"
                class="my-0 py-4"
                header-color="primary"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  v-model="filters.validation_date_range"
                  :label="$t('validation_date_range')"
                  append-icon="mdi-calendar"
                  readonly
                  chips
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.validation_date_range"
                range
                no-title
                color="primary"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:item.stock_adjustment_status="{ item }">
        <span
          v-if="item.stock_adjustment_status == 'canceled'"
          class="red--text mb-2 font-weight-medium text-capitalize"
          >canceled</span
        >
        <span
          v-if="item.stock_adjustment_status == 'closed'"
          class="grey--text mb-2 font-weight-medium text-capitalize"
          >Closed</span
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import stockAdjustmentService from "@/store/services/stock-adjustment-service";

export default {
  computed: {
    ...mapGetters({
      isLoadingStockAdjustments: "stockAdjustments/isLoadingStockAdjustments",
      stockAdjustments: "stockAdjustments/list",
      stockAdjustmentsMeta: "stockAdjustments/meta",
      hubs: "hubs/activeHubs",
    }),

    isVisibleDialog: {
      get() {
        return this.$store.state.stockAdjustments.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("stockAdjustments/IS_VISIBLE_DIALOG", value);
      },
    },
    filters: {
      get() {
        return this.$store.state.stockAdjustments.filters;
      },
      set(value) {
        this.$store.commit("stockAdjustments/SET_FILTERS", value);
      },
    },
  },
  props: {
    isActive: {
      types: Boolean,
    },
  },

  watch: {
    options: {
      handler: debounce(async function (params) {
        if (this.isActive) {
          await this.$store
            .dispatch("stockAdjustments/list", {
              ...params,
              ...this.filters,
            })
            .then(() => {})
            .catch(() => {
              this.$store.dispatch("alerts/error", "stockAdjustment not found");
            });
        }
      }, 1000),
      deep: true,
    },

    filters: {
      handler: debounce(async function (params) {
        if (this.isActive) {
          await this.$store
            .dispatch("stockAdjustments/list", {
              ...params,
              ...this.options,
            })
            .then(() => {})
            .catch(() => {
              this.$store.dispatch(
                "alerts/error",
                "stockAdjustments not found"
              );
            });
        }
      }, 1000),
      deep: true,
    },

    search_hub: debounce(function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;

      this.$store
        .dispatch("hubs/list", {
          search,
        })
        .then(() => {
          this.is_loading_hubs = false;
        });
    }, 1000),
  },

  data() {
    return {
      search_hub: "",
      is_loading_hubs: false,
      options: {},
      index: 0,
      is_loading: false,
      creation_date: null,
      currentAdjustment: null,

      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "tracking_number",
        },
        {
          text: "Update date",
          align: "left",
          sortable: true,
          value: "update_date",
        },
        {
          text: "Update by",
          align: "left",
          sortable: true,
          value: "update_by",
        },
        {
          text: this.$t("hub"),
          align: "start",
          sortable: false,
          value: "hub.name",
        },
        {
          text: this.$t("stockAdjustments.number_of_items"),
          align: "center",
          sortable: true,
          value: "products_count",
        },
        {
          text: "status",
          align: "center",
          sortable: true,
          value: "stock_adjustment_status",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    showItem(item) {
      this.$router.push({
        name: "stock.adjustments.view",
        params: { id: item.id },
      });
    },
    async downloadItem(item) {
      const BDC = await stockAdjustmentService.getAdjustmentSlip(item);
      const url = window.URL.createObjectURL(new Blob([BDC]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${item.tracking_number}.pdf`);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
